import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/shared/helper-services/toast.service';

@Component({
  selector: 'app-invite-member-modal',
  templateUrl: './invite-member-modal.component.html',
  styleUrl: './invite-member-modal.component.scss',
})
export class InviteMemberModalComponent implements OnInit {
  public formGroup: FormGroup = new FormGroup({});

  @Output() sendInviteOutput = new EventEmitter<boolean>();

  constructor(
    private dialogRef: MatDialogRef<InviteMemberModalComponent>,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  public sendInvite(): void {
    this.closeModal();
    const invitedPerson = this.formGroup.value;
    this.authService.sendInvite(invitedPerson).subscribe({
      next: () => {
        this.sendInviteOutput.emit(true);
      },
      error: () => {
        this.toastService.showToast($localize`Failed to invite new member`, 'error');
      },
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  private initForm(): void {
    this.formGroup.addControl('name', this._formBuilder.control('', [Validators.required]));
    this.formGroup.addControl('email', this._formBuilder.control('', [Validators.required, Validators.email]));
  }

  public get isFormValid(): boolean {
    return this.formGroup.valid;
  }
}
