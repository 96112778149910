<div *ngIf="field === 'status'; else skeleton">
  <app-badge *ngIf="isItemProcessing(status)" [label]="'Processing...'" [color]="'gray'"/>
  <app-badge *ngIf="status === processStatus.STRUCTUREREADY" [label]="'Structure Ready'" [color]="'green'"/>
  <app-badge *ngIf="status === processStatus.ERROR" [label]="'Error'" [color]="'red'"/>
</div>
<ng-template #skeleton>
  <ngx-skeleton-loader
    *ngIf="field != 'status' && isItemProcessing(status); else columnsTemplate"
    count="1"
    animation="progress-dark"
    [theme]="{ 'margin-bottom': '0px', height: '8px' }"
  >
  </ngx-skeleton-loader>
</ng-template>
