<main-box>
  <div class="flex flex-col w-full h-full gap-5 bg-gray-50">
    <div class="flex flex-col w-full gap-2 p-4">
      <div i18n class="text-2xl font-semibold text-osavus-black m-0">Members</div>
      <div i18n class="text-sm font-normal text-gray-500">
        Invite new members to provide them with access to the organization's job advertisements and candidate
        information.
      </div>
    </div>

    <div class="w-full h-full overflow-hidden">
      <app-table
        [columns]="columns"
        [endPoint]="'organization/recruiter'"
        [tableConfig]="tableConfig"
        [refreshTrigger]="refreshTrigger"
        (listCountUpdated)="onListCountUpdated($event)"
      >
        <ng-template tableCustomCell="fullName" let-item>
          <span class="font-semibold">{{ item.fullName }}</span>
        </ng-template>

        <ng-template tableCustomCell="email" let-item>
          <item-copy [value]="item.email" />
        </ng-template>

        <ng-template tableCustomCell="registeredDate" let-item>
          <span class="text-gray-500 font-semibold">{{ item.registeredDate | date: 'MMM d y hh:mm a' }}</span>
        </ng-template>

        <ng-template tableCustomCell="emailConfirmed" let-item>
          <app-badge *ngIf="item.emailConfirmed" [label]="'Active'" [color]="'green'" />
          <app-badge *ngIf="!item.emailConfirmed" [label]="'Invite sent'" [color]="'gray-400'" />
        </ng-template>

        <ng-template #rowActions let-data>
          <div *ngIf="!data.emailConfirmed; else noAction">
            <button
              class="resend-button mt-1 mr-2"
              mat-icon-button
              matTooltip="Resend"
              (click)="onOpenResendModal({ name: data.fullName, email: data.email })"
              [ngStyle]="{
                'background-color': '#f5f5f5'
              }"
            >
              <img src="assets/resend-arrow.svg" alt="info_icon" />
            </button>
          </div>
          <ng-template #noAction>
            <div class="mr-8"> </div>
          </ng-template>
        </ng-template>

        <ng-template #tableActions>
          <app-primary-button
            [label]="'Invite Member'"
            [icon]="'icon-plus'"
            (click)="openAddMemberModal()"
          ></app-primary-button>
        </ng-template>
      </app-table>
    </div>
  </div>
</main-box>

<app-confirmation-modal
  [message]="'Are you sure you want to resend the invitation?'"
  (onConfirm)="onModalConfirm()"
></app-confirmation-modal>
