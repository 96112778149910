import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableComponent } from 'src/app/components/table/table.component';
import { JobsService } from '../../../services/jobs.service';
import { ToastService } from 'src/app/shared/helper-services/toast.service';
import { IListCount } from 'src/app/components/table/models/list-count.interface';

@Component({
  selector: 'app-apply-exsting-candidates',
  templateUrl: './apply-existing-candidates.component.html',
  styleUrl: './apply-existing-candidates.component.scss',
})
export class ApplyExistingCandidatesComponent implements OnInit {
  public isLoading: boolean = true;
  public refreshTrigger: boolean = false;
  public hasInitialData: boolean = true;
  public candidatesEndpoint: string = '';

  @Output() applyCandidatesSubmit = new EventEmitter<boolean>();
  @ViewChild(TableComponent) tableComponent!: TableComponent;

  public columns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'CANDIDATE NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    {
      field: 'activeJobTitle',
      header: 'CURRENT ROLE',
      sortable: true,
      searchKey: 'activeJobTitle',
    },
    {
      field: 'location',
      header: 'LOCATION',
      sortable: true,
      searchKey: 'location',
    },
    {
      field: 'createdAt',
      header: 'DATE ADDED',
      sortable: true,
    },
  ];

  public tableConfig: ITableConfig = {
    enableRowActions: false,
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt',
    } as ISievePayload,
  };

  constructor(
    private jobService: JobsService,
    private dialogRef: MatDialogRef<ApplyExistingCandidatesComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.candidatesEndpoint = `organization/candidates/list-can-apply/${this.data.jobId}`;
  }

  get isSelectedCandidates(): boolean {
    return this.tableComponent?.selection?.selected.length > 0;
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public applySelectedCandidates() {
    const selectedCandidates = this.tableComponent?.selection?.selected;
    this.jobService.applyCandidates(this.data.jobId, selectedCandidates).subscribe({
      next: (result) => {
        this.applyCandidatesSubmit.emit(true);
        this.tableComponent.handleDeleteResponse(selectedCandidates.length);
      },
      error: (e) => {
        this.dialogRef.close();
        this.toastService.showToast($localize`Failed to apply new candidates, please try again`, 'error');
      },
    });
  }
}
