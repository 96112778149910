<div class="container-settings">
	<div class="sidebar">
	  <h1>Settings</h1>
  	  <button
		(click)="selectedIndex = 0"
		[ngClass]="{'active': selectedIndex === 0}"
		class="tab-button">
		<h4>Profile</h4>
		<p>Edit your account information</p>
	  </button>
		<button
			(click)="selectedIndex = 1"
			[ngClass]="{'active': selectedIndex === 1}"
			class="tab-button nested">		
		<h4>Members</h4>
	</button>
  	  <button 
	 	class="tab-button disabled" disabled>
		<h4>Billing</h4>
		<p>Transactions, payment details</p>
	  </button>
  	  <button class="tab-button disabled" disabled>
		<h4>Integrations</h4>
		<p>Transactions, payment details</p>
	  </button>
	</div>
  	<ng-container>
	  <div class="content" *ngIf="selectedIndex === 0">
		<app-profile-settings></app-profile-settings>
	  </div>
		<app-members class="p-4 w-4/5"  *ngIf="selectedIndex === 1"></app-members>
	  <div class="content" *ngIf="selectedIndex === 2">
		<app-billing></app-billing>
	  </div>
	</ng-container>
</div>