import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { languages } from 'src/app/shared/store/languages';

interface FieldConfig {
  type: string;
  name: string;
  label: string;
  placeholder?: string;
  options?: any[];
  value?: any;
}

@Component({
  selector: 'app-edit-candidate',
  templateUrl: './edit-candidate.component.html',
  styleUrl: './edit-candidate.component.scss'
})
export class EditCandidateComponent {
  form: FormGroup;
  languageList: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditCandidateComponent>,
    private fb: FormBuilder
  ) {
    this.form = this.buildForm(this.data.fields);
    this.languageList = languages;
  }

  buildForm(fields: FieldConfig[]): FormGroup {
    const group: { [key: string]: FormControl } = {};
    fields.forEach(field => {
      group[field.name] = new FormControl(field.value || '');
    });
    return new FormGroup(group);
  }
  
  onSave() {
    const updatedValues = this.form.value;
    if (this.data.section === 'projects' || this.data.section === 'awardsAndHonors' || this.data.section === 'references' || this.data.section === 'affiliations' || this.data.section === 'hobbies') {
      const updatedProject = {
        title: updatedValues.title,
        content: updatedValues.content,
      };
      this.dialogRef.close(updatedProject);
    } else {
      let updatedData = { ...this.data.candidateData };
      this.data.fields.forEach((field: any) => {
        const formValue = updatedValues[field.name];
        const finalValue = field.transformTo ? field.transformTo(formValue) : formValue;
        if (field.path) {
          this.setValueAtPath(updatedData, field.path, finalValue);
        }
      });
  
      this.dialogRef.close(updatedData);
    }
  }
  

  setValueAtPath(obj: any, path: string, value: any): void {
    const segments = path.replace(/\[(\d+)\]/g, '.$1').split('.');
    let current = obj;
    for (let i = 0; i < segments.length; i++) {
      const seg = segments[i];
      if (i === segments.length - 1) {
        current[seg] = value;
      } else {
        if (current[seg] === undefined) {
          const nextSeg = segments[i + 1];
          if (Number.isInteger(+nextSeg)) {
            current[seg] = [];
          } else {
            current[seg] = {};
          }
        }
        current = current[seg];
      }
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }
}

