import { Observable, catchError, take, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endpoint: string = `${environment.link}/auth`;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  registerWithEmail(accountData: UpdateAccountData) {
    return this.http
      .post<any>(`${this.endpoint}/register`, accountData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  verifyEmail(verifyEmailData: VerifyEmailData) {
    return this.http
      .post<any>(`${this.endpoint}/verify-email`, verifyEmailData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  loginWithEmail(loginData: LoginData) {
    return this.http
      .post<any>(`${this.endpoint}/login`, loginData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  resendCode(resendData: ResendData) {
    return this.http
      .post<any>(`${this.endpoint}/resend-email-verification`, resendData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  signupWithGoogleSSO(data: GoogleToken) {
    return this.http
      .post<any>(`${this.endpoint}/google-sso-login`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  forgetPass(forgetPassData: ForgetPassData) {
    return this.http
      .post<any>(`${this.endpoint}/forgot-password`, forgetPassData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  resetPass(resetPassData: ResetPassData) {
    return this.http
      .post<any>(`${this.endpoint}/reset-password`, resetPassData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  userData() {
    return this.http
      .get<any>(`${this.endpoint}/current-user-details`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  overview() {
    return this.http
      .get<OverviewData>(`${environment.link}/overview`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  getInviteDetails(inviteCode: string) {
    return this.http
      .get<InviteDetails>(`${this.endpoint}/invite-details/${inviteCode}`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  profileSettings() {
    return this.http
      .get<any>(`${environment.link}/user/profile`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  updateProfileSettings(data: any) {
    return this.http
      .put<any>(`${environment.link}/user/profile`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  sendInvite(invitedPerson: InvitedPerson) {
    return this.http
      .post<any>(`${environment.link}/organization/recruiter/send-invitations`, invitedPerson, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  getOrganizationMembers() {
    return this.http
      .get<any>(`${environment.link}/organization/recruiter`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  mailChimpSubmit(data: any) {
    return this.http.jsonp<MailChimpResponse>(data, 'c');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  setRefreshToken(token: string): void {
    localStorage.setItem('refreshToken', token);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    this.router.navigate(['login-portal/login']);
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value;
      if (!password) {
        return null;
      }
      const errors: ValidationErrors = {};
      if (!/[A-Z]/.test(password)) errors['missingUppercase'] = true;
      if (!/[a-z]/.test(password)) errors['missingLowercase'] = true;
      if (!/\d/.test(password)) errors['missingNumber'] = true;
      if (!/[^a-zA-Z\d\s]/.test(password)) errors['missingSpecial'] = true;
      return Object.keys(errors).length ? errors : null;
    };
  }

  public static getPasswordValidationErrors(
    control: AbstractControl,
  ): string | null {
    if (control.errors) {
      if (control.errors['required']) {
        return 'Password is required';
      } else if (control.errors['minlength']) {
        return 'Password must be at least 8 characters long';
      } else if (control.errors['missingUppercase']) {
        return 'Password must contain at least one uppercase letter';
      } else if (control.errors['missingLowercase']) {
        return 'Password must contain at least one lowercase letter';
      } else if (control.errors['missingNumber']) {
        return 'Password must contain at least one number';
      } else if (control.errors['missingSpecial']) {
        return 'Password must contain at least one special character';
      }
    }
    return null;
  }

  refreshAccessToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    return this.http
      .post<any>(`${this.endpoint}/refresh-token`, {
        refreshToken,
      })
      .pipe(
        tap((response) => {
          this.setToken(response.jwtToken);
          this.setRefreshToken(response.refreshToken);
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        }),
      );
  }
}

export interface UpdateAccountData {
  email: string;
  fullName: string;
  password: string;
  country: string;
  isAgreeToTcAndPrivacyPolicy: boolean;
  isAllowSendPromotionalEmails: boolean;
}

export interface VerifyEmailData {
  email: string;
  verificationCode: number;
}

export interface ForgetPassData {
  email: string;
}

export interface ResetPassData {
  email: string;
  resetToken: string;
  password: string;
}

export interface LoginData {
  username: string;
  password: string;
}

export interface VerifyEmailResponse {
  userId: string;
  username: string;
  jwtToken: string;
  jwtTokenExpiration: string;
}

export interface ResendData {
  email: string;
}

export interface GoogleToken {
  code: string;
  redirectUri: string;
}

export interface MailChimpResponse {
  result: string;
  msg: string;
}

export interface OverviewData {
  totalJobs: number;
  totalCandidates: number;
  name: string;
}

export interface InvitedPerson {
  name: string;
  email: string;
}

export interface InviteDetails {
  name: string,
  email: string,
  orgName: string
}