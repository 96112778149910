<div class="relative flex min-h-full gap-20 p-10 bg-gray-50 justify-center">
  <div class="self-center flex flex-col flex-1 w-1/2 p-6 bg-white lg:flex-none rounded-2xl h-fit shadow max-w-2xl">
    <div class="w-full mx-auto">
      <div>
        <div class="pb-9">
          <img class="h-9 w-44" src="assets/full-Logo.svg" alt="Osavus" />
        </div>
        <span i18n class="primary-title">
          Create your account.
        </span>
        <p class="font-semibold leading-6 text-osavus-content-primary" *ngIf="invite">
          <span i18n>You’ve been invited to join <span [textContent]="inviteOrgName" class="text-osavus-blue-50"></span> as a member on Osavus!</span>
        </p>
        <p class="text-sm font-normal leading-6 text-osavus-content-primary" *ngIf="!invite">
          <span i18n>Already have an account?
          </span>
          <a i18n routerLink="../login" class="font-semibold text-osavus-blue-50 hover:text-osavus-primary-70">Log in
            here</a>
        </p>
      </div>

      <div class="mt-2">
        <div>
          <form [formGroup]="signupForm" ngNativeValidate class="space-y-5">
            <input type="hidden" name="inviteCode" [value]="invite" />
            <div class="flex flex-row justify-between gap-3">
              <div class="w-1/2">
                <label i18n for="email"
                  class="block text-sm font-semibold leading-6 text-osavus-content-primary">Email</label>
                <div class="mt-2">
                  <input id="email" name="email" type="email" autocomplete="email" formControlName="email"
                    placeholder="yourname@domain.com" required [readonly]="invite"
                    class="fileds" />
                </div>
              </div>
              <div class="w-1/2">
                <label i18n for="fullName"
                  class="block text-sm font-semibold leading-6 text-osavus-content-primary">Full Name</label>
                <div class="mt-2">
                  <input id="fullName" name="fullName" type="text" formControlName="fullName" placeholder="Full Name"
                    required
                    class="fileds" />
                </div>
              </div>
            </div>

            <div class="flex flex-row justify-between gap-3">
              <div class="w-1/2">
                <div>
                  <label i18n for="country"
                    class="block text-sm font-semibold leading-6 text-osavus-content-primary">Country</label>
                  <select id="country" name="country" formControlName="country" required
                    class="country">
                    <option class="font-semibold text-gray-500" value="" disabled selected i18n>
                      Choose your country
                    </option>
                    <ng-container *ngFor="let country of countries">
                      <option [value]="country.code">{{ country.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="w-1/2">
                <label i18n for="password"
                  class="block text-sm font-semibold leading-6 text-osavus-content-primary">Create a Password</label>
                <div class="mt-2">
                  <input id="password" name="password" type="password" autocomplete="current-password"
                    formControlName="password" placeholder="" required
                    class="password" />
                </div>
                <div class="mt-1 text-xs text-gray-500" i18n>
                  Password needs at least 8 characters with a number, an uppercase and lowercase letter, and a special
                  character.
                </div>
                <div *ngIf="
                    signupForm.get('password')?.touched &&
                    signupForm.get('password')?.invalid
                  " class="mt-1 text-xs text-red-500">
                  {{ passwordErrors }}
                </div>
              </div>
            </div>

            <div class="relative" *ngIf="!invite">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-500"></div>
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span i18n class="px-6 font-semibold text-gray-500 bg-white">or sign in with</span>
              </div>
            </div>

            <div class="flex gap-3" *ngIf="!invite">
              <div (click)="getAccessToken()"
                class="google">
                <img class="-ml-0.5 h-5 w-5" src="assets/google.svg" alt="Google" />
                <span i18n>Google</span>
              </div>
            </div>

            <div>
              <div class="flex">
                <input id="isAgreeToTcAndPrivacyPolicy" name="isAgreeToTcAndPrivacyPolicy" type="checkbox"
                  formControlName="isAgreeToTcAndPrivacyPolicy" required
                  class="w-4 h-4 mt-1 border-gray-300 rounded cursor-pointer bg-gray-50 text-osavus-blue-50 focus:ring-blue-600" />
                <label for="isAgreeToTcAndPrivacyPolicy"
                  class="block ml-2 mb-2 text-xs leading-6 text-gray-500 cursor-pointer">
                  <span i18n class="text-sm text-osavus-content-primary font-semibold leading-5">
                    By signing up, you are creating an account with Osavus and agree to its
                  </span>
                  <a i18n routerLink="/terms-and-conditions" target="_blank"
                    class="font-semibold text-sm text-osavus-blue-50 hover:text-blue-500 leading-5">
                    Terms and Conditions</a>
                  <span i18n class="text-sm text-osavus-content-primary font-semibold leading-5"> and </span>
                  <a i18n routerLink="/privacy-policy" target="_blank"
                    class="font-semibold text-sm text-osavus-blue-50 hover:text-blue-500 leading-5">
                    Privacy Policy</a>.
                </label>
              </div>
              <div class="flex">
                <input id="isAllowSendPromotionalEmails" name="isAllowSendPromotionalEmails" type="checkbox"
                  formControlName="isAllowSendPromotionalEmails"
                  class="w-4 h-4 mt-1 border-gray-300 rounded cursor-pointer bg-gray-50 text-osavus-blue-50 focus:ring-blue-600" />
                <label i18n for="isAllowSendPromotionalEmails"
                  class="block ml-2 text-sm font-semibold leading-5 text-osavus-content-primary cursor-pointer">
                  Email me about product updates and resources. We do not share your email or personal details with
                  third parties.
                </label>
              </div>
            </div>

            <div>
              <button (click)="onSubmit()" [disabled]="isRegistering" [ngClass]=" isRegistering
                    ? 'cursor-not-allowed bg-gray-400 text-gray-800 hover:bg-gray-400'
                    : 'cursor-pointer bg-osavus-blue-50 text-white'"
                class="register">
                <div i18n>Create account</div>
                <div role="status" class="mx-2" *ngIf="isRegistering">
                  <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="relative flex-1 hidden w-0 lg:block max-w-2xl">
    <img class="absolute inset-0 w-full h-full" src="assets/Signup.svg" alt="" />
  </div>
</div>

<ng-container *ngIf="showModal">
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
        <div
          class="relative flex flex-col gap-5 px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:p-6">
          <div class="flex justify-center w-full">
            <img class="h-9 w-[170px] pb-2" src="assets/full-Logo.svg" alt="Osavus" />
          </div>
          <h1 i18n class="text-2xl pt-2 font-semibold text-center text-osavus-content-primary">
            Verify your email address
          </h1>
          <div class="text-sm text-center text-osavus-content-primary">
            <p i18n class="font-normal mb-1">An email has been sent to:</p>
            <span class="font-semibold  text-osavus-blue-50 hover:text-blue-500">{{ signupForm.value.email }}</span>
          </div>
          <form class="space-y-6" [formGroup]="verifyForm" ngNativeValidate>
            <div>
              <label for="verificationCode" class="text-sm font-normal text-center text-osavus-content-primary" i18n>Please enter the
                verify code we have sent to verify your email
                address</label>
              <div class="mt-2">
                <input id="verificationCode" name="verificationCode" formControlName="verificationCode" type="string"
                  pattern=".{6,}" required
                  class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div>
              <button type="submit" (click)="verifyAccount()" [ngClass]="
                  isVerifying
                    ? 'cursor-not-allowed bg-gray-400 text-gray-800'
                    : 'cursor-pointer bg-osavus-blue-50 text-white hover:bg-gray-400'
                " [disabled]="isVerifying"
                class="flex w-full justify-center items-center rounded-md bg-osavus-blue-50 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-osavus-blue-60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                <div i18n>Verify Email</div>
                <div role="status" class="mx-2" *ngIf="isVerifying">
                  <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
              <div (click)="isResendable && resendVerifyCode()" [ngClass]="isResendable ? 'text-osavus-primary cursor-pointer' : ' '" 
                  class="flex items-center justify-end w-full gap-1 text-sm text-gray-400 cursor-not-allowed"> Resend email ({{ timeLeft }}s)
                <svg [ngClass]="isResending ? 'block' : 'hidden'" aria-hidden="true"
                  class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900" viewBox="0 0 100 101"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
                </svg>
              </div>
            </div>
          </form>
          <p i18n class="text-sm font-normal text-center text-osavus-content-primary">
            Did you make a typo on your email?
            <span (click)="changeEmail()" class="cursor-pointer text-osavus-blue-50 hover:text-blue-500 font-semibold">Register again</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>