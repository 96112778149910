import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobsService } from '../../services/jobs.service';
import { ToastService } from 'src/app/shared/helper-services/toast.service';

@Component({
  selector: 'app-generate-job-modal',
  templateUrl: './generate-job-modal.component.html',
  styleUrl: './generate-job-modal.component.scss',
})
export class GenerateJobModalComponent implements OnInit {
  private _formGroup: FormGroup = new FormGroup({});
  private _isFormSubmitted: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<GenerateJobModalComponent>,
    private _formBuilder: FormBuilder,
    private router: Router,
    private jobsService: JobsService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  public generateJobAd(): void {
    this.closeModal();
    this.router.navigate(['hiring-portal/jobs/job/generateJob']);
    const form = this.formGroup.value;

    this.jobsService.generateWithPrompt(form.title, form.description).subscribe({
      next: (result) => {
        this.router.navigate(['hiring-portal/jobs/job', result.body.id]).then(() => {
          window.location.reload();
        });
      },
      error: (e) => {
        this.router.navigate(['hiring-portal/jobs/list']);
        this.toastService.showToast($localize`Failed to generate new job ad`, 'error');
      },
    });
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  private initForm(): void {
    this._formGroup.addControl('title', this._formBuilder.control('', [Validators.required]));
    this._formGroup.addControl('description', this._formBuilder.control('', [Validators.required]));
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }

  public get formValid(): boolean {
    return this._formGroup.valid;
  }

  public get isFormSubmitted(): boolean {
    return this._isFormSubmitted;
  }
}
