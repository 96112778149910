import { Observable, catchError, filter, repeat, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  jobendpoint: string = `${environment.link}/job`;
  jobskillsendpoint: string = `${environment.link}/job-skills`;

  constructor(private http: HttpClient) {}

  createJob(jobTitle: string) {
    return this.http
      .post<any>(`${this.jobendpoint}`, { jobTitle: jobTitle } as CreateJobData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  generateWithPrompt(title: string, content: string) {
    return this.http
      .post<any>(`${this.jobendpoint}/generate`, {title: title, content: content} as JobPromptData, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  generateWithFile(data: FormData) {
    return this.http
      .post<any>(`${this.jobendpoint}/upload`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  applyJob(jobId: string, data: FormData) {
    return this.http
      .post<any>(`${this.jobendpoint}/${jobId}/apply`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  applyCandidates(jobId: string, candidates: string[]) {
    return this.http
    .post<any>(`${this.jobendpoint}/${jobId}/add-org-candidates`, {'ids': candidates}, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  getJobById(id: string, token?: string | null) {
    let headers = new HttpHeaders();

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    return this.http
      .get<any>(`${this.jobendpoint}/${id}`, {
        headers: headers,
        responseType: 'json',
        observe: 'response',
      })
      .pipe(
        filter((res) => res.body.jobSummary !== null),
        take(1),
      );
  }

  updateJobAd(data: IJobData) {
    return this.http
      .put<any>(`${this.jobendpoint}`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  reGenerateSection(data: IRegJobData) {
    return this.http
      .post<any>(`${this.jobendpoint}/regenerate-section`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  getJobs(payload: IGetJobsPayload) {
    return this.http
      .get<any>(this.jobendpoint, {
        responseType: 'json',
        observe: 'response',
        params: {
          ...payload,
        },
      })
      .pipe(take(1));
  }

  // Job Ad Skills

  getJobSkills(id: string) {
    return this.http
      .get<any>(`${this.jobendpoint}/${id}/skill`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(
        filter((res) => !!res.body.length),
        take(1),
      );
  }

  deleteJobSkill(id: string) {
    return this.http
      .delete<any>(`${this.jobskillsendpoint}/${id}`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  deleteJob(id: number) {
    return this.http
      .delete<any>(`${this.jobendpoint}/${id}`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  batchDeleteJobs(ids: string[]): Observable<any> {
    const options = {
      body: { ids },
      responseType: 'json' as const,
      observe: 'response' as const,
    };
    return this.http
      .request<any>('DELETE', `${this.jobendpoint}/batch-delete`, options)
      .pipe(take(1));
  }

  createJobSkill(data: ISkillData) {
    return this.http
      .post<any>(`${this.jobskillsendpoint}`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  updateJobSkill(data: ISkillData) {
    return this.http
      .put<any>(`${this.jobskillsendpoint}`, data, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(take(1));
  }

  getCandidates(jobId: string) {
    return this.http
      .get<IJobCandidate[]>(`${this.jobendpoint}/${jobId}/candidate`)
      .pipe(take(1));
  }

  public getJobFile(id: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.jobendpoint}/${id}/file`, {
      responseType: 'blob',
      observe: 'response',
    })
  }
}

export interface CreateJobData {
  jobTitle: string;
}

export interface JobPromptData {
  title: string,
  content: string,
  includeCompanyDetails: boolean;
  generatorTone: number;
}

export interface JobfileData {
  includeCompanyDetails: boolean;
  file: File;
}

export interface IJobData {
  id: string;
  status: string;
  statusMessage: null;
  jobTitle: string;
  jobType: string;
  companyName: string;
  companyContact: null;
  companyDescription: string;
  companyWebsite: string;
  companyEmail: string;
  datePosted: null;
  jobSummary: string;
  jobResponsibilities: string[];
  benefitsAndCompensation: string[];
  location: string;
  applicationProcess: string[];
  endDate: null;
  contactInformation: null;
  disclaimer: null;
  languages: {};
  fileName: string;
  imageFileName: null;
  generatorTone: number;
  salaryMin: string;
  salaryMax: string;
  salary: string;
  applied: number;
  headCount: number;
  jobLink: string;
  createdAt: Date;
  createdBy: string;
  externalStatus: JobExternalStatus;
  skillTags: string[];
  // computed
  hasAllData?: boolean;
}

export interface IRegJobData {
  jobId: string;
  prompt: string;
  generatorTone: number;
  section: ISection;
}

export enum ISection {
  TITLE = 'Title',
  SUMMARY = 'Summary',
  RESPONSIBILITIES = 'Responsibilities',
  BENEFITSANDCOMPENSATION = 'BenefitsAndCompensation',
  COMPANYDESCRIPTION = 'CompanyDescription',
  APPLICATIONPROCESS = 'ApplicationProcess',
}

export enum SkillType {
  SOFT = 'Soft',
  HARD = 'Hard',
}
export interface IJobSkill {
  id: string;
  skill: string;
  level: number;
  description: string;
  skillType: SkillType;
  jobId: string;
}

export interface ISkillCreateData {
  skill: string;
  description: string;
  aiRelevancy: number;
  jobId: string;
}

export interface ISkillUpdateData {
  id: string;
  skill: string;
  description: string;
  aiRelevancy: number;
}

export interface ISkillData {
  id?: string;
  skill: string;
  description: string;
  aiRelevancy?: number;
  jobId?: string;
}

export enum JobExternalStatus {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  ARCHIVED = 'Archived',
}

export interface IGetJobsPayload {
  sorts?: string;
  filters?: string;
  page: number;
  pageSize: number;
}

export interface IJobCandidate {
  id: string;
  jobId: string;
  orgCandidateId: string;
  fullName: string;
  email: string;
  profileCompleteness: number;
  activeJobTitle: any;
  location: any;
  jobMatchScore: number;
  externalStatus: JobCandidateStatus;
  activityStatus: string;
  originalFileDownloadLink?: string;
  githubLink?: string;
  linkedInLink?: string;
  createdAt: string;
}

export enum JobCandidateStatus {
  APPLIED = 'Applied',
  GOOD_MATCH = 'GoodMatch',
  FOR_REVIEW = 'ForReview',
  PRE_SCREENING = 'PreScreening',
  HIRED = 'Hired',
  UNQUALIFIED = 'Unqualified',
}
