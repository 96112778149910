import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
  provideHttpClient,
  withJsonpSupport,
} from '@angular/common/http';
import * as Sentry from "@sentry/angular";
import {APP_INITIALIZER, ErrorHandler, NgModule, importProvidersFrom} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './modules/auth/auth.module';
import {HiringPortalModule} from './modules/hiring-portal/hiring-portal.module';
import {AuthInterceptor} from './services/http-interceptor.service';
import {MaterialModule} from './material.module';
import {UploaderModule} from '@syncfusion/ej2-angular-inputs';
import {IconRegistryService} from './shared/helper-services/icon-registry.service';
import {Router} from "@angular/router";
import { ToastComponent } from './core-ui/toast/toast.component';
import { CoreUIModule } from './core-ui/core-ui.module';

@NgModule({
  declarations: [AppComponent],
  exports: [MaterialModule, UploaderModule],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HiringPortalModule,
    AuthModule,
    MaterialModule,
    UploaderModule,
    CoreUIModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    IconRegistryService,
    importProvidersFrom(HttpClientModule, HttpClientJsonpModule),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideAnimationsAsync(),
    provideHttpClient(withJsonpSupport()),
  ],
})
export class AppModule {
}
