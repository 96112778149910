<main-box>
  <div class="candidate-page">
    <div class="candidate-page-header" *ngIf="!candidateFromJob">
      <div class="candidate-page-header-back">
        <i class="icon icon-back-arrow" aria-hidden="true" (click)="onBack()"></i>
      </div>
      <div class="candidate-page-header-title">Candidates</div>
      <div class="candidate-page-header-spacer"></div>
      <!-- <div
        class="candidate-page-header-add-new-button"
        role="button"
        (click)="onAddNewCandidate()"
      >
        <i class="icon icon-plus" aria-hidden="true"></i>
        <span>Add candidate</span>
      </div> -->
    </div>

    <div [ngClass]="{ 'no-close-padding': !showCloseButton }">
      <button *ngIf="showCloseButton && candidateFromJob" mat-button (click)="sidenavClose()">
        <img alt="close_icon" src="assets/close-icon.svg" class="w-3 h-3 flex cursor-pointer" />
      </button>
    </div>

    <div class="candidate-page-content">
      <div class="candidate-page-content-left-bar" *ngIf="!candidateFromJob">
        <!-- <div class="candidate-page-content-left-bar-search">
          <div class="candidate-page-content-left-bar-search-container">
            <i class="icon icon-search"></i>
            <input class="search-input" placeholder="Search" />
          </div>
        </div> -->
        <app-candidate-list class="candidate-page-content-left-bar-list" (select)="onCandidateSelect($event)"></app-candidate-list>
      </div>
      <app-loading *ngIf="isLoading" class="candidate-page-content-main relative"></app-loading>
      <div *ngIf="!isLoading" class="candidate-page-content-main">
        <app-candidate-meta-data [data]="candidateData"></app-candidate-meta-data>
        <div class="h-full overflow-y-auto" #candidateContentMain>
          <div class="candidate-page-content-main-ai">
            <div class="candidate-page-content-main-ai-summary" *ngIf="candidateData?.generatedSummary">
              <div class="flex gap-2 items-center">
                <div class="flex bg-white rounded-full w-4 h-4 items-center justify-center">
                  <img src="assets/magic-sparkles.svg" class="w-2 h-2" />
                </div>
                <div class="title flex">AI Summary</div>
              </div>
              <div>
                {{ candidateData?.generatedSummary }}
              </div>
            </div>
          </div>
          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="candidateData?.summary || candidateData?.contact" [expanded]="true">
              <mat-expansion-panel-header >
                <mat-panel-title>About</mat-panel-title>
                <div class="flex gap-1 items-center pr-4" (click)="openCandidateEditModal('contact'); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
              </mat-expansion-panel-header>
              <div class="font-medium text-sm pb-4">{{ candidateData?.summary }}</div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Email</span>
                <div class="w-2/3 flex gap-2 overflow-auto items-center">
                  <span class="accordion-value">{{ candidateData?.contact?.email }}</span>
                  <span role="button" class="flex w-5 h-5 bg-gray-100 items-center justify-center rounded-full pl-2">
                    <img 
                      src="assets/copy-outline.svg"
                      alt="copy" 
                      class="w-3 h-3" 
                      title="Copy email"
                      [cdkCopyToClipboard]="candidateData?.contact?.email || ''"
                      (cdkCopyToClipboardCopied)="onEmailCopy(tooltip)"/>
                    <span
                      class="ml-2"
                      #tooltip="matTooltip"
                      matTooltip="Copied!"
                      matTooltipPosition="right"
                      matTooltipClass="mat-tooltip-success"
                    ></span>
                  </span>
                  <a [href]="'mailto:' + candidateData?.contact?.email"
                    class="flex w-5 h-5 bg-blue-100 items-center justify-center rounded-full">
                    <img src="assets/envelope-blue.svg" class="w-3 h-3" />
                  </a>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Phone</span>
                <div class="w-2/3 flex gap-2 overflow-auto">
                  <span class="accordion-value">{{candidateData?.contact?.phone}}</span>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Linkedin</span>
                <div class="w-2/3 flex gap-2 overflow-auto">
                  <span class="accordion-value">{{ candidateData?.contact?.linkedIn}}</span>
                </div>
              </div>
              <div class="accordion-item">
                <span class="accordion-label w-1/3">Languages</span>
                <div class="w-2/3 flex gap-2 overflow-auto">
                  <span class="accordion-value pill" *ngFor="let item of languages">{{ item }}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="candidateData?.skills?.length || candidateData?.softSkills?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Skills</span>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{candidateData?.skills?.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="py-3 w-full flex mb-4" *ngIf="candidateData?.softSkills?.length">
                <span
                  class="text-osavus-gray-800 text-xs font-semibold me-3 px-3.5 py-1.5 rounded bg-gray-100 text-black-400"
                  *ngFor="let item of candidateData?.softSkills">
                  {{ item }}
                </span>
              </div>
              <div>
                <div class="accordion-item flex" *ngIf="candidateFromJob">
                  <div class="w-1/3 h-14">
                    <div *ngFor="let item of candidateData?.skills"
                      class="text-osavus-gray-800 text-xs font-semibold me-3 mb-3 px-3.5 py-1.5 rounded bg-gray-100 text-black-400 truncate" matTooltip="{{ item?.skill }}">
                      {{ item?.skill }}
                    </div>
                  </div>
                  <div class="w-2/3">
                    <div *ngFor="let item of candidateData?.skills" class="h-10">
                      <div class="flex">
                        <div 
                          class="skill-box w-1/10 h-8 mx-1"   
                          *ngFor=" let filled of getFullArray(item?.level);let idx = index" 
                          [ngClass]="{
                            progress: idx < (item?.level ?? 0), 
                            max: idx === (item?.level ?? 0) - 1
                          }">
                          <span class="text-white content-center mr-auto ml-auto text-xs" *ngIf="idx === (item?.level ?? 0) - 1">{{ idx + 1 }}</span>
                        </div>
                      </div>
                      <div class="flex">
                        <div 
                          class="job-skill-box w-1/10 h-8 mx-1"   
                          *ngFor="let filled of getFullArray(item?.jobSkillLevel ?? 0); let idx = index" 
                          [ngClass]="{
                            jobprogress: idx < (item?.jobSkillLevel ?? 0), 
                            jobmax: idx === (item?.jobSkillLevel ?? 0) - 1
                          }">
                          <span class="text-white content-center mr-auto ml-auto text-xs" *ngIf="idx === (item?.jobSkillLevel ?? 0) - 1">{{ idx + 1 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item flex" *ngIf="!candidateFromJob">
                  <div class="w-1/3">
                    <ng-container *ngFor="let item of candidateData?.skills; let i = index">
                      <div *ngIf="item.skillType === 'Hard'" 
                           class="text-osavus-gray-800 text-xs font-semibold me-3 mb-5 px-3.5 py-1.5 rounded bg-gray-100 text-black-400 truncate" 
                           matTooltip="{{ item?.skill }}">
                        {{ item?.skill }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="w-2/3">
                    <ng-container *ngFor="let item of candidateData?.skills; let i = index">
                      <div class="flex">
                        <div *ngIf="item.skillType === 'Hard'" class="flex w-full">
                          <div *ngFor="let filled of getFullArray(item?.level); let idx = index"
                               class="candidate-box w-1/10 h-8 mx-1"
                               [ngClass]="{
                                 'bg-green-500': filled,
                                 'bg-gray-200': !filled,
                                 progress: idx < (item?.level ?? 0),
                                 max: idx === (item?.level ?? 0) - 1
                               }">
                            <span class="text-white content-center mr-auto ml-auto text-xs" *ngIf="idx === (item?.level ?? 0) - 1">{{ idx + 1 }}</span>
                          </div>
                        </div>
                        <div *ngIf="item.skillType === 'Hard'" class="p-2" (click)="openCandidateEditModal('skills', i); $event.stopPropagation();">
                          <img src="/assets/edit-icon.svg" />
                        </div>
                      </div>
                    
                    </ng-container>
                  </div>
                </div>                

              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="candidateData?.jobs?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Work History</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ candidateData?.jobs?.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

            <ng-container *ngFor="let item of candidateData?.jobs; let i = index">
              <div class="p-1 justify-items-end" (click)="openCandidateEditModal('jobs', i); $event.stopPropagation();">
                <img src="/assets/edit-icon.svg" />
              </div>
              <div class="accordion-item" >
                <div class="w-1/3 mr-6">
                  <span class="accordion-value">{{ item?.title }}</span>
                  <span class="text-sm pb-3 font-semibold text-gray-500 flex w-full">{{ item?.company }}</span>
                    
                    <div *ngIf="!item.endDate">
                      <div *ngIf="heights[i] > 84; else horizontalLine">
                        <div class="px-2 py-1 rounded-md bg-gray-100 text-gray-500 text-sm font-semibold w-fit mb-3">{{ item.experience }}</div>
                        <div class="text-gray-500 font-semibold w-14 text-sm">{{ item.endDate ? (item.endDate | date: "shortDate") : "Present" }}</div>
                        <div class="pl-4 py-2">
                          <hr class="min-h-20 mx-3 border-gray-200 border-l border-t-0 border-b-0 border-r-0" [style.height.px]="heights[i] - 90" />
                        </div>
                        <div class="text-gray-500 font-semibold w-14 text-sm whitespace-nowrap">{{ item.startDate | date: "MMM d y " }}</div>
                      </div>
                      <ng-template #horizontalLine>
                        <div class="px-2 py-1 rounded-md bg-gray-100 text-gray-500 text-sm font-semibold w-fit mb-3">{{ item.experience }}</div>
                        <div class="flex">
                          <div class="text-gray-500 font-semibold w- text-sm">{{ item.endDate ? (item.endDate | date: "shortDate") : "Present" }}</div>
                          <hr class="w-4 my-3 mx-4 mt-2 border-gray-300" />
                          <div class="text-gray-500 font-semibold w- text-sm whitespace-nowrap">{{ item.startDate | date: "MMM d y " }}</div>
                        </div>
                      </ng-template>
                    </div>

                    <div *ngIf="item.endDate">
                      <div *ngIf="heights[i] > 84; else horizontalLine">
                        <div class="px-2 py-1 rounded-md bg-gray-100 text-gray-500 text-sm font-semibold w-fit mb-3">{{ item.experience }}</div>
                        <div class="text-gray-500 font-semibold w-14 text-sm whitespace-nowrap">{{ item.startDate | date: "MMM d y " }}</div>
                        <div class="pl-4 py-2">
                          <hr class="min-h-20 mx-3 border-gray-200 border-l border-t-0 border-b-0 border-r-0" [style.height.px]="heights[i] - 90" />
                        </div>
                        <div class="text-gray-500 font-semibold w-14 text-sm whitespace-nowrap">{{ item.endDate | date: "MMM d y " }}</div>
                      </div>
                      <ng-template #horizontalLine>
                        <div class="px-2 py-1 rounded-md bg-gray-100 text-gray-500 text-sm font-semibold w-fit mb-3">{{ item.experience }}</div>
                        <div class="flex">
                          <div class="text-gray-500 font-semibold w- text-sm whitespace-nowrap">{{ item.startDate | date: "MMM d y " }}</div>
                          <hr class="w-4 my-3 mx-4 mt-2 border-gray-300" />
                          <div class="text-gray-500 font-semibold w- text-sm whitespace-nowrap">{{ item.endDate | date: "MMM d y " }}</div>
                        </div>
                      </ng-template>
                    </div>
                </div>
                <div class="w-2/3">
                  <span #descriptionRef class="font-medium text-sm">{{ item.description }}</span>
                </div>
              </div>
            </ng-container>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="candidateData?.educations?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Education</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ candidateData?.educations?.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngFor="let item of candidateData?.educations; let i = index">
                <div class="p-1 justify-items-end" (click)="openCandidateEditModal('educations', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <div class="accordion-item flex-wrap">
                  <div class="accordion-value w-full">
                    {{ item.institution }}
                  </div>
                  <div class="w-full text-gray-400 text-sm">{{ item.degrees[0] }}</div>
                  <div class="accordion-value w-full">{{ item.graduationDate | date: "MMM d y " }}</div>
                </div>
              </ng-container>

            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="candidateData?.certAndTrainings?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Certificates and Trainings</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ candidateData?.certAndTrainings?.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of candidateData?.certAndTrainings; let i = index">
                <div class="p-1 justify-items-end mb-[-25px]" (click)="openCandidateEditModal('certAndTrainings', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <span class="font-semibold text-sm">{{ item.name }}</span>
                <br />
                <span class="font-semibold text-sm">{{ item.issuer }}</span>
                <br />
                <span class="text-sm">{{ item.description }}</span>
              </div>
              <div class="accordion-item" *ngIf="!candidateData?.certAndTrainings?.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="projectArray?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Projects</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{projectArray.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of projectArray; let i = index">
                <div class="p-1 justify-items-end mb-[-25px]" (click)="openCandidateEditModal('projects', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <span class="font-semibold text-sm">{{ item.title }}</span><br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!projectArray.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="awardsAndHonors?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Awards and Honors</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ awardsAndHonors.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of awardsAndHonors; let i = index">
                <div class="p-1 justify-items-end mb-[-25px]" (click)="openCandidateEditModal('awardsAndHonors', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!awardsAndHonors.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="candidateData?.volunteerExperiences?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Volunteer experience</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{candidateData?.volunteerExperiences?.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of candidateData?.volunteerExperiences; let i = index">
                <div class="p-1 justify-items-end mb-[-25px]" (click)="openCandidateEditModal('volunteerExperiences', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <span class="font-semibold text-sm">{{ item.organization }}</span>
                <br />
                <span class="text-sm">{{ item.role }}</span>
              </div>
              <div class="accordion-item" *ngIf="!candidateData?.volunteerExperiences?.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel  *ngIf="references?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>References</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ references.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of references; let i = index">
                <div class="p-1 justify-items-end mb-[-25px]" (click)="openCandidateEditModal('references', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!references.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel *ngIf="affiliations?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Affiliations</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ affiliations.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of affiliations; let i = index">
                <div class="p-1 justify-items-end mb-[-25px]" (click)="openCandidateEditModal('affiliations', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!affiliations.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel  *ngIf="candidateData?.publications?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Publications</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{candidateData?.publications?.length}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-item" *ngFor="let item of candidateData?.publications">
                <span class="text-sm">{{ item }}</span>
              </div>
              <div class="accordion-item" *ngIf="!candidateData?.publications?.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel  *ngIf="hobbies?.length" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div>Hobbies</div>
                  <div class="counter-badge">
                    <span class="counter-badge-value">{{ hobbies.length }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="accordion-data" *ngFor="let item of hobbies; let i = index">
                <div class="p-1 justify-items-end mb-[-25px]" (click)="openCandidateEditModal('hobbies', i); $event.stopPropagation();">
                  <img src="/assets/edit-icon.svg" />
                </div>
                <span class="font-semibold text-sm">{{ item.title }}</span>
                <br />
                <span class="text-sm">{{ item.content }}</span>
              </div>
              <div class="accordion-item" *ngIf="!hobbies.length">
                <span class="accordion-label">No data</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div class="candidate-page-content-rigth-bar" *ngIf="!candidateFromJob"></div>
    </div>
  </div>
</main-box>