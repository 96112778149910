<div class="upload-modal">
    <div class="upload-modal-header">
      <div class="flex items-center">
        <span class="upload-modal-header-text">{{ data.modalTitle }}</span>
        <img alt="close_icon" src="assets/close-icon.svg" (click)="closeModal()" class="w-3 h-3 flex cursor-pointer ml-auto" />
      </div>
      <span class="text-sm font-normal text-gray-500">{{ data.modalSubText }}</span>
    </div>
  
    <div class="upload-modal-content">
      <div [formGroup]="form" class="form-container">
        <div *ngFor="let field of data.fields" class="field-group">
          <ng-container [ngSwitch]="field.type">
      
            <div *ngSwitchCase="'input'">
              <app-input
                [id]="field.name"
                [name]="field.name"
                [label]="field.label"
                [placeholder]="field.placeholder"
                formControlName="{{field.name}}"
                ngDefaultControl>
              </app-input>
            </div>
      
            <div *ngSwitchCase="'multi-select'">
              <app-multi-select
                [id]="field.name"
                [name]="field.name"
                [label]="field.label"
                i18n-label i18n-placeholder
                autocomplete="off"
                [placeholder]="field.placeholder"
                [options]="languageList"
                formControlName="{{field.name}}"
                ngDefaultControl>
              </app-multi-select>
            </div>

            <div *ngSwitchCase="'textarea'">
              <app-textarea   
                [id]="field.name"
                [name]="field.name"
                [label]="field.label"
                i18n-label i18n-placeholder 
                autocomplete="off"
                [placeholder]="field.placeholder"
                formControlName="{{field.name}}"
                [rows]="3" 
                ngDefaultControl>
              </app-textarea>
            </div>

            <div *ngSwitchCase="'date'">
              <app-label class="mb-1 text-sm" [title]="field.label"></app-label>
              <div class="relative">
                <input
                  matInput
                  [matDatepicker]="picker"
                  [id]="field.name"
                  [name]="field.name"
                  [placeholder]="field.placeholder"
                  formControlName="{{field.name}}"
                  class="datepicker"
                />
                <mat-datepicker-toggle matSuffix [for]="picker" class="absolute right-2 top-1/2 transform -translate-y-1/2"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="flex">
        <button type="button" class="cancel" (click)="closeModal()">Cancel</button>
        <button type="button" class="save" (click)="onSave()">Save</button>
      </div> 
    </div>
  </div>
