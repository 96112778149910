import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { IBulkActionItem } from 'src/app/components/table/models/bulk-action-item.interface';
import { MatDialog } from '@angular/material/dialog';
import { ApplyExistingCandidatesComponent } from './apply-existing-candidates/apply-existing-candidates.component';
import { TableComponent } from 'src/app/components/table/table.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-candidates',
  templateUrl: './job-candidates.component.html',
  styleUrl: './job-candidates.component.scss',
})
export class JobCandidatesComponent implements OnInit {
  public isLoading: boolean = true;
  public hasInitialData: boolean = true;
  public statusRefreshTrigger = false;
  public openUploadModal: boolean = false;
  public candidateUploadUrl: string = '';

  @Output() sendCandidates = new EventEmitter<string>();
  @Output() public openSidenav = new EventEmitter<string | null>();

  @ViewChild(TableComponent) tableComponent!: TableComponent;

  selectedCandidateIds: any;
  candidateId!: string;
  jobId: string = '';
  candidateEndpoint: string = '';
  sidenav: any;

  get isCompareDisabled(): boolean {
    return this.tableComponent?.selection?.selected.length !== 2;
  }

  public columns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'CANDIDATE NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    {
      field: 'email',
      header: 'EMAIL',
      sortable: true,
      searchKey: 'email',
    },
    {
      field: 'activeJobTitle',
      header: 'CURRENT ROLE',
      sortable: true,
      searchKey: 'activeJobTitle',
    },
    {
      field: 'location',
      header: 'LOCATION',
      sortable: true,
      searchKey: 'location',
    },
    {
      field: 'jobMatchScore',
      header: 'SCORE',
      sortable: true,
    },
    {
      field: 'source',
      header: 'SOURCE',
      sortable: true,
    },
    // {
    //   field: 'activityStatus',
    //   header: 'STATUS',
    //   sortable: true,
    // },
    // {
    //   field: 'rating',
    //   header: 'RATING',
    //   sortable: true,
    // },
    {
      field: 'createdAt',
      header: 'DATE ADDED',
      sortable: true,
    },
  ];

  public tableConfig: ITableConfig = {
    enableRowActions: false,
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-jobMatchScore',
    } as ISievePayload,
  };

  public bulkActions: IBulkActionItem[] = [
    { label: 'Apply Existing', value: 'existingCandidates', icon: 'assets/add-new.svg' } as IBulkActionItem,
    { label: 'Upload New', value: 'uploadNew', icon: 'assets/file-upload.svg' } as IBulkActionItem,
  ];

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['jobId'];
    this.candidateEndpoint = `job/${this.jobId}/candidate`;
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  onItemSelect(id: string | null) {
    if (id) {
      this.candidateId = id;
      this.openSidenav.emit(id);
    }
  }

  public onBulkAction(action: IBulkActionItem) {
    if (action.value === 'existingCandidates') {
      this.openApplyExistingCandidatesModal();
    } else if (action.value === 'uploadNew') {
      this.candidateUploadUrl = `${environment.link}/job/${this.jobId}/cv/upload`;
      this.openUploadModal = true;
      this.statusRefreshTrigger = true;
    }
  }

  public onUploadModalClose(isOpen: boolean): void {
    if (this.openUploadModal && !isOpen) {
      this.openUploadModal = false;
      this.statusRefreshTrigger = false;
    }
  }

  compareCandidates() {
    this.selectedCandidateIds = this.tableComponent.selection.selected;
    this.sendCandidates.emit(this.selectedCandidateIds);
  }

  openApplyExistingCandidatesModal() {
    const dialogRef = this.dialog.open(ApplyExistingCandidatesComponent, {
      height:'700px',
      maxHeight: '800px',
      maxWidth: '800px',
      minWidth: '800px',
      data: {
        jobId: this.jobId,
      },
      disableClose: true,
    });

    dialogRef.componentInstance.applyCandidatesSubmit.subscribe((result) => {
      this.statusRefreshTrigger = true;
    });

    dialogRef.afterClosed().subscribe(() => {
      this.statusRefreshTrigger = false;
      dialogRef.componentRef?.destroy();
    });
  }
}
