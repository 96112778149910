import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HiringPortalRoutingModule } from './hiring-portal-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { OverviewComponent } from './components/overview/overview.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { HelpsComponent } from './components/helps/helps.component';
import { DocsComponent } from './components/docs/docs.component';
import { HiringHeaderComponent } from './components/hiring-header/hiring-header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { JobsModule } from './modules/jobs/jobs.module';
import { CoreUIModule } from 'src/app/core-ui/core-ui.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './components/settings/settings.component';
import { ProfileSettingsComponent } from './components/settings/profile-settings/profile-settings.component';
import { CandidatesModule } from './modules/candidates/candidates.module';
import { BillingComponent } from './components/settings/billing/billing.component';
import { MembersComponent } from './components/settings/members/members.component';
import { InviteMemberModalComponent } from './components/settings/members/invite-member-modal/invite-member-modal.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    DashboardComponent,
    OverviewComponent,
    MessagesComponent,
    AnalyticsComponent,
    HelpsComponent,
    DocsComponent,
    HiringHeaderComponent,
    SidebarComponent,
    SettingsComponent,
    ProfileSettingsComponent,
    BillingComponent,
    MembersComponent,
    InviteMemberModalComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    HiringPortalRoutingModule,
    JobsModule,
    CoreUIModule,
    ComponentsModule,
    ReactiveFormsModule,
    CandidatesModule,
    CoreUIModule,
    DirectivesModule,
    MatButtonModule,
    MatTooltip,
  ],
})
export class HiringPortalModule {}
