import { Component, ViewChild } from '@angular/core';
import { IListCount } from 'src/app/components/table/models/list-count.interface';
import { IMatColumn } from 'src/app/components/table/models/mat-column.interface';
import { ITableConfig } from 'src/app/components/table/models/table-config.model';
import { TableComponent } from 'src/app/components/table/table.component';
import { ISievePayload } from 'src/app/shared/models/sieve-payload.interface';
import { ToastService } from 'src/app/shared/helper-services/toast.service';
import { InviteMemberModalComponent } from './invite-member-modal/invite-member-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { AuthService, InvitedPerson } from 'src/app/services/auth.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss',
})
export class MembersComponent {
  public isLoading: boolean = true;
  public refreshTrigger: boolean = false;
  public hasInitialData: boolean = true;
  public statusRefreshTrigger: boolean = false;
  public inviteSelection!: InvitedPerson;

  @ViewChild(TableComponent) tableComponent!: TableComponent;
  @ViewChild(ConfirmationModalComponent) confirmationModal!: ConfirmationModalComponent;

  public columns: IMatColumn[] = [
    {
      field: 'fullName',
      header: 'NAME',
      sortable: true,
      searchKey: 'fullname',
    },
    {
      field: 'email',
      header: 'EMAIL',
      sortable: true,
      searchKey: 'email',
    },
    {
      field: 'emailConfirmed',
      header: 'STATUS',
      sortable: true,
    },
    {
      field: 'registeredDate',
      header: 'JOINED DATE',
      sortable: true,
    },
  ];

  public tableConfig: ITableConfig = {
    defaultPayload: {
      page: 1,
      pageSize: 25,
      sorts: '-createdAt,emailConfirmed',
    } as ISievePayload,
  };

  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  get isSelectedCandidates(): boolean {
    return this.tableComponent?.selection?.selected.length > 0;
  }

  public onListCountUpdated(listCount: IListCount): void {
    this.hasInitialData = listCount.initialCount > 0;
    this.isLoading = false;
  }

  openAddMemberModal() {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
    const dialogRef = this.dialog.open(InviteMemberModalComponent, {
      height: isMobile ? '90%' : 'auto',
      maxHeight: isMobile ? '90%' : '690px',
      width: isMobile ? '90%' : '750px',
      disableClose: true,
    });

    dialogRef.componentInstance.sendInviteOutput.subscribe(() => {
      this.refreshTrigger = true;
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogRef.componentRef?.destroy();
    });
  }

  onOpenResendModal(invitedPerson: InvitedPerson) {
    this.inviteSelection = invitedPerson;
    this.confirmationModal.openModal();
  }

  onModalConfirm() {
    this.authService.sendInvite(this.inviteSelection).subscribe({
      error: () => {
        this.toastService.showToast($localize`Failed to invite new member`, 'error');
      },
      complete: () => {
        this.refreshTrigger = true;
      },
    });
  }
}
